<template>
    <div class="pt-1 px-3">
        <div class="h-100 flex-fill d-flex flex-column">
            <div class="">
                <router-link to="/bussiness" class="h4 mb-0 text-white d-flex align-items-center" style="">
                    <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                    Back
                </router-link>
            </div>

            <h3 class="text-center font-weight-bolder text-white mt-2">Vet Clinic Form</h3> 

            <form id="frm">
                
                <div class="d-flex mt-2">
                    <div class="m-auto position-relative">
                        <img :src="url ? url : (input_data.vet_picture ? input_data.vet_picture : default_img)" id="user_preview" class="bg-white" 
                            style="border-radius: 10px;height:180px;width:180px;object-fit: cover;" />
                        <div @click="selectPicture()" class="position-absolute d-flex rounded-circle" style="bottom: 10px;right: 10px;background-color: #1B628C;padding:.5em">
                            <i class="bx bxs-camera m-auto" style="font-size: 18px;color: white;"></i>
                        </div>
                        <div id="form">
                            <input type="file" @change="onFileChange" name="vet_picture" id="vet_picture" class="d-none" accept="image/*" />
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div class="badge badge-danger text-white m-auto" v-if="(!url && !input_data.vet_picture) && isSubmit" 
                        style="margin-top: .5em;">Vet picture not valid</div>
                </div>

                <div class="mt-2">
                    <label class="h3 text-white">Vet Clinic Name</label>
                    <div class="input-group">
                        <span class="input-group-text text-white"><i class="bx bx-building"></i></span>
                        <input class="form-control" name="inp[vet_name]" placeholder="Vet Clinic Name" v-model="input_data.vet_name">
                    </div>
                    <div class="text-white" style="margin-top: .5em;">*Vet name is atleast 2 character</div>
                    <div class="badge badge-danger text-white" v-if="!valid.vet_name && isSubmit" style="margin-top: .5em;">Vet name not valid, atleast 2 character</div>
                </div>

                <div class="mt-2">
                    <label class="h3 text-white">Location</label>
                    <div class="input-group">
                        <span class="input-group-text text-white"><i class="bx bx-ap"></i></span>
                        <input class="form-control" name="inp[vet_location]" placeholder="Location" v-model="input_data.vet_location">
                    </div>
                    <div class="text-white" style="margin-top: .5em;">*Location is atleast 2 character</div>
                    <div class="badge badge-danger text-white" v-if="!valid.vet_location && isSubmit" style="margin-top: .5em;">Location not valid, atleast 2 character</div>
                </div>

                <div class="mt-2">
                    <label class="h3 text-white">Phone</label>
                    <div class="input-group">
                        <span class="input-group-text text-white"><i class="bx bx-phone"></i></span>
                        <input class="form-control" name="inp[vet_phone]" placeholder="Phone" v-model="input_data.vet_phone">
                    </div>
                    <div class="text-white" style="margin-top: .5em;">*Phone is atleast 2 character</div>
                    <div class="badge badge-danger text-white" v-if="!valid.vet_phone && isSubmit" style="margin-top: .5em;">Phone not valid, atleast 2 character</div>
                </div> 

                <div class="mt-2">
                    <label class="h3 text-white">Description</label>
                    <div class="input-group">
                        <span class="input-group-text text-white"><i class="bx bx-comment"></i></span>
                        <textarea class="form-control" name="inp[vet_details]" placeholder="Description" v-model="input_data.vet_details"></textarea>
                    </div>
                    <div class="badge badge-danger text-white" v-if="!valid.vet_details && isSubmit" style="margin-top: .5em;">Note not valid, atleast 16 character</div>
                </div>  

                <div class="mt-2">
                    <label class="h3 text-white">Prove of Identity</label>
                    <div class="input-group">
                        <span class="input-group-text text-white"><i class="bx bx-image"></i></span>
                        <input class="form-control" type="file" name="vet_docs" placeholder="Phone">
                    </div>
                    <div class="text-white" style="margin-top: .5em;">*This will be the document to verify your service</div>
                </div>

            </form>

            <div class="accordion mt-2" role="tablist">
                <b-card no-body class="mb-0">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="primary">Terms & Condition</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body style="max-height: 200px;overflow-y: scroll;">
                            <p class="">
                                Thank you for your interest in joining the Mongrela Vet Association. Before becoming a part of our esteemed community, please take a moment to familiarize yourself with our terms and conditions. <br/> <br/>
                                Membership is open to licensed veterinarians who uphold the highest standards of professional conduct. By joining, you commit to providing compassionate and expert care to animals. Our association encourages active participation, professional collaboration, and the exchange of knowledge among members. Membership fees and any additional contributions will be communicated upon approval of your application. <br/> <br/>
                                Mongrela Vet Association reserves the right to review and accept applications at its discretion. Your agreement to these terms signifies your commitment to excellence in veterinary practice and your dedication to the welfare of animals. We look forward to welcoming you to our community.
                            </p>
                        </b-card-body>
                    </b-collapse>
                </b-card> 
            </div>

            <div class="mt-2 w-100 d-flex flex-column justify-content-center" style="gap:10px">
                <button class="btn text-white flex-fill" style="background-color: #187294;height:50px;font-size: 18px;"
                    @click="onSubmit()">
                    Im Ready!
                </button>
            </div>

        </div>
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie';
let cookies = new Cookies()
import {BCardHeader, BCard, BCollapse, BCardBody, BButton} from 'bootstrap-vue'

export default {
    components: {
        BCardHeader, BCard, BCollapse, BCardBody, BButton
    },
    computed: {
        user(){
            return store.state.auth.USER
        },
        vet(){
            return this.user?.vet
        },
        valid(){
            return {
                vet_name : this.input_data.vet_name?.length > 2,
                vet_location : this.input_data.vet_location?.length > 2, 
                vet_phone : this.input_data.vet_phone?.length > 2, 
                vet_details : this.input_data.vet_details?.length > 15,  
                picture: this.url || this.input_data.vet_picture
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    watch: {
        vet: {
            handler: function(val){
                if(val){
                    this.input_data = val
                }
            }, immediate: true, deep: true
        }
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
        },
        selectPicture(){
            $('#vet_picture').click()
        },
        onSubmit() {
            this.isSubmit = true
            if(this.allValid){
                let payload = new FormData($('#frm')[0])
                payload.append('inp[user_id]', cookies.get('token'))
                if(this.vet?.vet_id){
                    payload.append('id', this.vet?.vet_id)
                }
    
                store.dispatch('vet/SetupVet', payload).then(() => {
                    store.dispatch('auth/GetUserData')
                })
            }
        }
    }, 
    data() {
        return {
            input_data: {},
            isSubmit: false,
            url: '',
            default_img : 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png',

        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus, textarea:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input, .input-group textarea {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 40px;
        color: white;
        background-color: transparent !important;
    } 

    select.form-control, select.form-control:active {
        color:white !important
    }
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>